import React from 'react';
import { handleAuthentication } from '~utils/auth';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';

const Callback = () => {
  handleAuthentication();

  return (
    <PageWrapper>
      <div
        style={{
          alignItems: `center`,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center'
        }}
      >
        <h1>Logging you in...</h1>
      </div>
    </PageWrapper>
  );
}

export default Callback;
